//@import "~bootstrap/dist/css/bootstrap.css";
@import "~react-tabs/style/react-tabs.css";

@import "Reset";
@import "Variables";
@import "IconFonts";
@import "Utils";
@import "Animations";

// Components
@import "Header";
@import "Button";
@import "Icon";
@import "Loading";

@tailwind components;
@tailwind base;
@tailwind utilities;

body {
  font-family: $primary-regular, sans-serif;
  font-size: $medium;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 100%;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  color: $white;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $black;

  &.ReactModal__Body--open {
    overflow: hidden;
  }

  &::-webkit-scrollbar {
    display: none; // Chrome, Safari, Opera
  }
}

h1, h2, h3, h4, h5, h6 {
  display: block;
  color: $white;
  line-height: 130%;
}

h1 {
  font-size: 56px;
  font-weight: 700;
}

h2 {
  font-size: 48px;
  font-weight: 700;

  .t-secondary {
    font-size: 90px;
  }

  @include for-mobile() {
    font-size: 24px;
    line-height: 30px;

    .t-secondary {
      font-size: 48px;
    }
  }
}

h3 {
  font-size: 24px;

  @include for-mobile() {
    font-size: 14px;
  }
}

h4 {
  font-size: 18px;

  @include for-mobile() {
    font-size: 16px;
  }
}

p {
  line-height: 22px;

  @include for-mobile() {
    font-size: 12px;
    line-height: 16px;
  }
}

strong {
  display: block;
  font-weight: 700;
}

b {
  font-family: $primary-bold;
}

a {
  color: $white;
}

button {
  line-height: 125%;
}

.t-secondary {
  font-family: 'Grey Qo', cursive;
  color: $yellow;
}

.scroll-down {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
  z-index: 98;
  background-color: $yellow !important;
  width: 48px;
  height: 48px;
  margin-right: 16px;

  i {
    color: $black !important;
  }

  @include for-mobile() {
    width: 24px;
    height: 24px;
  }
}

.copyright {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid $yellow;
  padding: 26px 0 38px;
  margin-top: 64px;

  .icon {
    width: auto;
    color: $yellow;
    margin-right: 10px;
  }

  @include for-mobile() {
    margin-top: 24px;
    padding-top: 24px;
    flex-direction: column-reverse;
    align-items: flex-start;

    ul {
      flex-direction: column;
      margin-bottom: 8px;
    }

    li {
      font-size: 12px;
      margin: 0 0 16px 0 !important;
    }
  }
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none; // Chrome, Safari, Opera
  }
}

.ReactModalPortal {
  position: fixed;
  z-index: 100;

  .close-btn {
    position: absolute;
    top: 2.5%;
    right: 64px;
    z-index: 1;
    color: $white;
  }

  .billboard-img {
    border-radius: 12px;
    overflow: hidden;
  }

  .slick-list, .slick-track, .slick-slide {
    overflow: hidden;
    border-radius: 12px;
  }

  .slick-next,
  .slick-prev {
    width: 36px;
    height: 36px;

    &:before {
      font-family: 'icomoon', serif !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      font-size: 36px;
    }
  }

  .slick-next {
    right: -50px;

    &:before {
      content: "\e932";
    }
  }

  .slick-prev {
    left: -50px;

    &:before {
      content: "\e931";
    }
  }

  .slick-dots {
    bottom: -24px;

    li {
      width: 12px;
      height: 12px;
      background-color: $white;
      border-radius: 100%;

      &.slick-active {
        border: none;
        background-color: $yellow;
      }

      button {
        padding: 0;

        &:before {
          content: none;
        }
      }
    }
  }

  @include for-mobile() {
    .close-btn {
      top: -10px;
      right: -8px;
    }
  }
}

.ReactModal__Content {
  margin: auto;
  width: 80%;
  max-width: 1200px;
  background-color: transparent !important;
  border: none !important;
  overflow: hidden !important;
  padding: 4% 100px 0 !important;

  @include for-mobile() {
    width: 100%;
    padding: 40px 8px 24px !important;
    inset: initial !important;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  background-color: rgba($black, 1) !important;

  @include for-mobile() {
    display: flex;
    align-items: center;
  }
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

@media print, screen and (max-width: 768px) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 767px) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 1024px) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 1023px) {
  .show-for-large {
    display: none !important;
  }
}

@import "pages/Home";
