.btn {
  height: 40px;
  border-radius: 20px;
  padding: 0 20px;
  @include flex-base();
  display: inline-flex;
  width: fit-content;

  span {
    font-size: 16px;
    white-space: nowrap;
    font-weight: 700;
  }

  &.btn--primary {
    background-color: $primary;

    i, span {
      color: $white;
    }
  }

  &.btn--primary-dark {
    background-color: $primary-dark;

    i, span {
      color: $white;
    }
  }

  &.btn--secondary {
    background-color: $white;

    span {
      color: $primary;
    }
  }

  &.btn--icon {
    &:not(.btn--primary):not(.btn--primary-dark) {
      padding: 0;
    }

    i {
      font-size: 18px;
      margin-right: 10px;
      margin-top: -2px;
    }
  }

  @include for-mobile() {
    height: 48px;

    span {
      font-size: 18px;
    }

    &.btn--icon {
      i {
        font-size: 16px;
      }
    }
  }
}
