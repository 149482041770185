.icon {
  width: 64px;
  height: unset;
  aspect-ratio: 1;
  border-radius: 100%;
  @include flex-base();

  &.icon--primary {
    background-color: $primary;

    i {
      color: $white;
    }
  }

  &.icon--secondary {
    background-color: $white;

    i {
      color: $primary;
    }
  }
}
