@font-face {
  font-family: 'Muli Bold';
  src: url('../fonts/Muli-Bold.eot');
  src: url('../fonts/Muli-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Muli-Bold.woff2') format('woff2'),
  url('../fonts/Muli-Bold.woff') format('woff'),
  url('../fonts/Muli-Bold.ttf') format('truetype'),
  url('../fonts/Muli-Bold.svg#Muli-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli Regular';
  src: url('../fonts/Muli-Regular.eot');
  src: url('../fonts/Muli-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Muli-Regular.woff2') format('woff2'),
  url('../fonts/Muli-Regular.woff') format('woff'),
  url('../fonts/Muli-Regular.ttf') format('truetype'),
  url('../fonts/Muli-Regular.svg#Muli-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli Light';
  src: url('../fonts/Muli-Light.eot');
  src: url('../fonts/Muli-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Muli-Light.woff2') format('woff2'),
  url('../fonts/Muli-Light.woff') format('woff'),
  url('../fonts/Muli-Light.ttf') format('truetype'),
  url('../fonts/Muli-Light.svg#Muli-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Didot';
  src: url('../fonts/UVFDidotLTStd-Italic.eot');
  src: url('../fonts/UVFDidotLTStd-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/UVFDidotLTStd-Italic.woff2') format('woff2'),
  url('../fonts/UVFDidotLTStd-Italic.woff') format('woff'),
  url('../fonts/UVFDidotLTStd-Italic.ttf') format('truetype'),
  url('../fonts/UVFDidotLTStd-Italic.svg#UVFDidotLTStd-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mussica';
  src: url('../fonts/Mussica.eot');
  src: url('../fonts/Mussica.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Mussica.woff2') format('woff2'),
  url('../fonts/Mussica.woff') format('woff'),
  url('../fonts/Mussica.ttf') format('truetype'),
  url('../fonts/Mussica.svg#Mussica') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --container-width: 1568px;
}

$primary-bold: 'Muli Bold'; // Weight 700
$primary-regular: 'Muli Regular'; // Weight 400
$primary-light: 'Muli Light'; // Weight 300

// Colors
$primary: #5CA79F;
$primary-dark: #3D7368;
$white: #ffffff;
$black: #333333;
$red: #E46239;
$orange: #FFBC97;
$gray: #C4C4C4;
$background: #FAFAFA;
$yellow: #FFE258;

  // Font Size
$small: 14px;
$medium: 16px;
$regular: 18px;
$large: 22px;
$x-large: 32px;
$xx-large: 36px;
$xxx-large: 48px;

// Mixin
@mixin flex-base($align: center, $justify: center) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin for-mobile {
  @media only screen and (max-width: 767px) { @content; }
}

@mixin for-tablet {
  @media only screen and (max-width: 1023px) { @content; }
}

@mixin for-desktop-only {
  @media only screen and (min-width: 1024px) { @content; }
}
