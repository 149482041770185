.max-width {
  padding: 0 40px;
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;

  @include for-tablet() {
    padding: 0 24px;
  }
}

.text {
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }

  &-default {
    color: $black !important;
  }

  &-primary {
    color: $primary !important;
  }
  &-primary-dark {
    color: $primary-dark !important;
  }

  &-white {
    color: $white !important;
  }
}

.p {
  &-relative {
    position: relative;
  }

  &-absolute {
    position: absolute;
  }

  &-fixed {
    position: fixed;
  }
}

.d {
  &-none {
    display: none !important;
  }
  &-block {
    display: block !important;
  }

  &-inline-block {
    display: inline-block !important;
  }

  &-flex {
    display: flex !important;
  }
}

.justify-self {
  &-start {
    justify-self: start;
  }

  &-end {
    justify-self: end;
  }

  &-center {
    justify-self: center;
  }
}

