.page--home {
  overflow: hidden;
  margin-top: -48px;

  .section:not(.section--top) {
    padding-top: 120px;

    .section-header {
      p {
        margin-top: 16px;
      }
    }

    @include for-mobile() {
      padding-top: 24px;

      .section-header {
        p {
          margin-top: 8px;
        }
      }
    }
  }

  .section--top {
    margin-top: 120px;
    position: relative;
    height: calc(100vh - 120px);
    background-image: url('~images/background.png');
    background-size: cover;

    &:after {
      content: "";
      background: linear-gradient(180deg, #333333 0%, rgba(51, 51, 51, 0) 49.48%, #333333 100%);
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
    }

    .section-inside {
      position: absolute;
      bottom: 0;
      left: 0;
      min-width: 100%;
      padding: 0 10% 100px;
      z-index: 1;

      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }

    h1 {
      max-width: 900px;
    }

    .scroll-down-wrapper {
      display: flex;
      align-items: center;
      min-width: 180px;
      margin-left: 60px;
    }

    @include for-mobile() {
      margin-top: 0;
      height: 100vh;

      .section-inside {
        padding: 0 0 20% 40px;
        position: relative;
        display: flex;
        align-items: flex-end;
        height: 100%;

        .content {
          padding: 0;
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .scroll-down-wrapper {
        margin: 64px 0 0 0;

        i {
          font-size: 16px !important;
        }
      }
    }
  }

  .section--howItWorks {
    .section-body {
      display: grid;
      grid-template-columns: auto minmax(700px, 50%);
      max-width: 90%;
      margin: 68px auto 0;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 4% 0;
      }
    }

    @include for-mobile() {
      .section-body {
        max-width: 100%;
        margin-top: 24px;
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }

  .section--features {
    .section-body {
      display: grid;
      grid-template-columns: 600px auto;
      grid-column-gap: 80px;
      max-width: 90%;
      margin: 68px auto 0;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10% 0;
      }
    }

    .feature-3-img {
      width: 80%;
    }

    @include for-mobile() {
      .section-body {
        display: flex;
        flex-direction: column-reverse;
        max-width: 100%;
        margin-top: 0;

        .content {
          flex-direction: row;
          padding: 40px 0 16px;
        }
      }
    }
  }

  .section--whatWeHave {
    .section-body {
      margin-top: 80px;

      .tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 100px;
      }

      .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 12px;

        &.tab--active {
          button {
            color: $white;
            background-color: $primary;
          }
        }

        button {
          height: 48px;
          font-size: 18px;
          color: $yellow;
          font-weight: 700;
          padding: 0 24px;
          border-radius: 24px;
          background-color: rgba($primary, 0.1);
          transition: background-color 0.2s linear;
        }
      }

      .billboard-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        .billboard {
          width: 25%;
          padding: 0 12px;
          margin-bottom: 10px;
        }
      }

      .billboard-img {
        position: relative;
        border-radius: 12px;
        margin-bottom: 16px;
        overflow: hidden;
        width: 100%;
        padding-bottom: 70%;
        background-size: cover;

        .icon {
          position: absolute;
          right: 10px;
          width: 40px;
          background-color: $white;
          top: 10px;
          z-index: 2;
          color: $primary;
        }
      }
    }

    @include for-mobile() {
      padding: 24px 0;

      .section-header {
        padding: 0 50px;
      }

      .section-body {
        margin-top: 24px;

        .tabs {
          padding: 0 32px;
          margin-bottom: 24px;
        }

        .tab {
          margin: 0 4px;

          button {
            height: 32px;
            padding: 0 16px;
            font-size: 12px;
          }
        }

        .billboard-list {
          flex-wrap: nowrap;
          overflow-x: auto;
          justify-content: flex-start;

          &::-webkit-scrollbar {
            display: none; // Chrome, Safari, Opera
          }

          .billboard {
            min-width: 75%;
          }
        }
      }
    }
  }

  .section--mobileApp {
    .section-body {
      margin-top: 60px;
    }

    ul {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin: 24px 0 54px;

      li {
        margin: 0 12px;
      }

      img {
        max-height: 80px;
        width: auto;
      }
    }

    .phone-download {
      max-width: 700px;
      margin: 0 auto;
    }

    @include for-mobile() {
      .section-body {
        margin-top: 48px;

        p {
          font-size: 16px;
        }
      }
    }
  }

  .section--footer {
    .section-body {
      display: flex;
      justify-content: center;

      .logo {
        max-width: 160px;
        margin-right: 40px;
      }

      li {
        margin-top: 16px;
      }
    }

    @include for-mobile() {
      .section-body {
        flex-direction: column;

        .logo {
          max-width: 104px;
          margin: 0 0 24px 0;
        }

        li {
          font-size: 12px;
          margin-top: 8px;
        }
      }
    }
  }

  .box {
    display: flex;

    &.box--right {
      padding-left: 10%;
    }

    &.box--active {
      .icon {
        background-color: $primary;
        transition: background-color 0.3s linear;

        i {
          color: $white;
        }
      }
    }

    .icon {
      width: 80px;
      height: 80px;
      border-radius: 100%;
      background-color: rgba($primary, 0.1);
      margin-right: 24px;

      i {
        color: $yellow;
      }
    }

    h3 {
      margin-bottom: 16px;
      padding-top: 30px;
    }

    @include for-mobile() {
      &.box--right {
        padding-left: 0;
      }

      .icon {
        width: 64px;
        height: 64px;
        margin-right: 16px;

        i {
          font-size: 26px !important;
        }
      }

      h3 {
        padding-top: 0;
        margin-bottom: 8px;
      }
    }
  }
}

.underconstruction {
  width: 100vw;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    margin-bottom: 12px;
    color: $black;
    font-weight: 700;
  }
}
