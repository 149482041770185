.header {
  z-index: 99;
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  background-color: $black;

  .header-inside {
    position: relative;
    min-height: 60px;
    grid-template-columns: 230px auto 230px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        margin-right: 32px;
        cursor: pointer;

        &.active {
          button {
            color: $yellow;
            font-weight: 700;
          }
        }
      }
    }
  }

  .header__left {
    width: 158px;
  }

  .header__right {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      color: $white;
    }
  }

  @include for-mobile() {
    background: linear-gradient(180deg, rgba(51,51,51,1) 0%, rgba(51,51,51,0.8225884103641457) 36%, rgba(2,0,36,0) 100%);

    .header-inside {
      height: 80px;
    }

    .header__left {
      width: 104px;
    }

    .header__right {
      display: none;
      opacity: 0;

      &.header__right--show {
        opacity: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        padding: 130px 48px 24px;

        ul {
          flex-direction: column;
          align-items: flex-start;

          li {
            margin-bottom: 36px;
            font-weight: 700;
            font-size: 24px;
            margin-right: 0;
            text-align: left;
          }
        }

        .btn {
          min-height: 56px;
          padding: 0 24px;
          border-radius: 28px;

          span {
            font-size: 20px;
          }
        }
      }
    }
  }
}
